<template>
  <v-dialog
    persistent
    v-model="dialog"
    @keydown.esc="dialog = false"
    width="850"
    scrollable
  >
    <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
      <v-card class="elevation-3">
        <v-card-title class="primary text-h5 white--text">
          {{ modalText }}
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12">
              <v-text-field
                label="Name"
                v-model="selectedLuCounty.Name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-text-field
                label="Population"
                v-model="selectedLuCounty.Population"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-text-field
                label="DOR Jurisdiction"
                v-model="selectedLuCounty.DORJurisdiction"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- CityCounty Join Items-->
          <v-row>
            <v-col sm="12">
              <LuCity
                label="City"
                v-model="selectedLuCounty.CityCounty"
                selfKey="CountyID"
                :selfID="selectedLuCounty.ID"
                relatedItemKey="CityID"
                joinItemName="CityCounty"
              ></LuCity>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-checkbox
                label="Is Active"
                v-model="selectedLuCounty.IsActive"
                :rules="[(v) => v === true || v === false || 'required']"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="saveLuCountyDetails" :disabled="saving" color="primary"
            >Save</v-btn
          >
          <v-btn outlined color="primary" @click="cancelEntry">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import LuCounty from '@classes/LuCounty'
//templateTODO: import lookup components you plan to use
import LuCity from '@components/select/multi-join/LuCity'
export default {
  components: {
    //templateTodo: define lookup components you are using
    LuCity,
  },
  data: () => ({
    dialog: false,
    valid: true,
    selectedLuCounty: new LuCounty(),
    modalText: '',
  }),
  created() {},
  computed: {
    ...get('luCounty', ['saving']),
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('luCounty', ['saveLuCounty']),
    async validate() {
      this.$refs.form.validate()
    },
    editLuCounty(entry) {
      this.selectedLuCounty = new LuCounty(entry)
      this.modalText = 'Edit County'
      this.dialog = !this.dialog
    },
    addLuCounty() {
      this.modalText = 'Insert County'
      this.selectedLuCounty = new LuCounty()
      this.dialog = !this.dialog
    },
    saveLuCountyDetails() {
      this.validate().then(() => {
        if (this.valid) {
          //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
          this.saveLuCounty(this.selectedLuCounty.removeRelated()).then(
            (res) => {
              this.$emit('refresh')
              this.selectedLuCounty = new LuCounty()
              this.$refs.form.resetValidation()
            },
            (err) => {}
          )
          this.dialog = false
        }
      })
    },
    cancelEntry() {
      this.dialog = false
      this.selectedLuCounty = new LuCounty()
      this.$refs.form.resetValidation()
    },
  },
}
</script>
<style></style>
